<template> 
    <div class="page-content  header-clear-small">
        <div id="preloader"><div class="spinner-border color-highlight" role="status"></div></div>
		<div class="card card-style" v-bind:style="{ backgroundImage: 'url(' + entity.background_url + ')' }">
            <div class="card-body my-3">
                <h5 class="badge bg-red-dark-sdw color-white px-1 py-1 font-12 mb-3">Sondages</h5>
                <h1 class="color-white">
                    {{entity.name}}
                </h1>
                <!--<p class="color-white opacity-60 mb-0">
                    text
                </p>-->
               
            </div>
            <div class="card-overlay bg-black opacity-20"></div>
        </div>

		<div  v-if="entity.polls && entity.polls.length > 0">
		<div class="card card-style" v-for="poll in entity.polls" v-bind:key="poll.id">
            <div class="content mb-4">
                <h4 class="font-600">{{poll.name}}</h4>
                <p class="opacity-80 mb-0">
                    {{poll.description}}
                </p>
				<p class="text-center">
				<a :href="poll.url + entity.id + '/' + mip" target="_blank" class="btn btn-s rounded-sm bg-orange-dark-sdw color-white mt-3 text-uppercase font-800"><i class="fa fa-play-circle pe-2"></i> &nbsp;Participer</a>
				</p>
            </div>            
        </div>		
		</div>
		<div  v-else>
		<div class="card card-style">
            <div class="content mb-4">
                <h6 class="font-600 text-center">Aucun sondage disponible !</h6>
				<p class="text-center">
					<a :href="'/commune/'+entity.id" class="btn btn-s rounded-sm bg-orange-dark-sdw color-white mt-3 text-uppercase font-600">Retour</a>
				</p>
            </div>            
        </div>		
		</div>		
		<Footer v-if="!loading"></Footer>		
	</div>
    <!-- End of Page Content--> 
</template>

<script>

import AppConstants from '@/config'
/*import { Plugins } from '@capacitor/core';*/
/*const { Browser } = Plugins;*/

export default {
  name: 'Sondage',
  data() {
    return{
	loading: false,
	entity: {},
	mip:0 // mobile-ip
	}
  },
  components: {
    Footer
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    isloggedin: {
      type: Number,
      default: 5
    }	
  },  
  created() {
     this.getEntity()
  },  
  mounted() {
	//
	this.getMip()
  }, 
	unmounted() {
     if (this.entity == {}) this.getEntity()
  },  
  methods: {
	getMip() {
		if (localStorage.getItem('mip') === null) {
				var rand = this.getRandomInt(999999999999);
				localStorage.setItem('mip', rand );
		}
		this.mip = localStorage.getItem('mip');
	
	},
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},   
	/*openUrl(url) {
	var r = url+this.id
		Browser.open({ url: r });
	
	},*/
	getEntity() {
				this.loading = true
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"anonymous-entity"				
				
                if(this.id != 0) {
                    var apiData = { 
					"entity": this.id,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
						this.entity = response.data.data
						this.loading = false
					}
				})
				.catch(error => {
				console.log(error.message);
				})	
				} 
	},	
  }
}


</script>
